import { assertIsDefined } from "../assertions";
import { AUTOMATIC_PARAMETERS } from "../automaticParameters";
import { flattenSortedCells, sortCells } from "../cell/index.js";
import { assertNever } from "../errors";
import { asciiCompare } from "../fractionalIndexing";
import { CellId } from "../idTypeBrands";
import { ParameterName, SecretName, VariableName } from "../typeBrands";
import {
  typedObjectEntries,
  typedObjectFromEntries,
  typedObjectKeys,
} from "../utils/typedObjects";

import { CellReferencesV2, PythonCellReferencesV2 } from "./cellReferencesV2";
import {
  GLOBAL_REACTIVE_PARAM_TYPES,
  ReactiveParamType,
} from "./projectGraphs";

export interface BaseGraphCell {
  id: CellId;
  order: string;
}

export interface InputParamInfo {
  parentCellId: CellId | undefined;
  type: ReactiveParamType;
}

export interface OutputParamInfo {
  children: CellId[];
  /** The CellId of the cell that this parameter is redefining the definition of */
  shadowedCellId?: CellId;
  type: ReactiveParamType;
}

export interface GraphNode<C> {
  inputParams: Record<VariableName, InputParamInfo>;
  outputParams: Record<VariableName, OutputParamInfo>;
  cell: C;
}
export type CellGraph<C> = Record<CellId, GraphNode<C>>;

export type CellReferenceMap<C> = Record<
  CellId,
  { cell: C; references: PythonCellReferencesV2 | CellReferencesV2 | null }
>;

export interface CellGraphData<C> {
  graph: CellGraph<C>;
  redefinitions: Record<VariableName, CellId[]>;
  missingDefinitions: Record<VariableName, CellId[]>;
}

export function getCellGraph<C extends BaseGraphCell>({
  cells,
  secrets,
}: {
  cells: CellReferenceMap<C>;
  secrets: SecretName[];
}): CellGraphData<C> {
  const sortedCells = flattenSortedCells(
    sortCells(Object.values(cells).map((c) => c.cell)),
  );
  const automaticParamSet = new Set<VariableName>(
    AUTOMATIC_PARAMETERS.map((p) => p.name as ParameterName),
  );
  const secretSet = new Set<VariableName>(secrets);
  // Values are stored in reverse order where most recent definition is index 0 and first definition is the last index
  const paramDefinitions: Record<VariableName, CellId[]> = {};
  const graph: CellGraph<C> = {};
  const missingDefinitions: Record<VariableName, CellId[]> = {};

  for (const cell of sortedCells) {
    const cellId = cell.id;
    const graphNode: GraphNode<C> = {
      cell,
      inputParams: {},
      outputParams: {},
    };
    graph[cellId] = graphNode;
    const references = cells[cell.id]?.references;
    // TODO(reactivity): when having null references (due to an error), should we cache the old results?
    const { newParams, referencedParams } = references ?? {
      newParams: [],
      referencedParams: [],
    };
    let importSet = new Set();
    let functionSet = new Set();
    if (PythonCellReferencesV2.guard(references)) {
      importSet = new Set(references.imports);
      functionSet = new Set(references.functions.map((f) => f.name));
    }

    for (const { param } of referencedParams) {
      const existing = paramDefinitions[param] ?? [];
      const parentCellId = existing[0];
      let type = automaticParamSet.has(param)
        ? ReactiveParamType.AUTOMATIC_PARAM
        : secretSet.has(param)
          ? ReactiveParamType.SECRET
          : ReactiveParamType.VARIABLE;
      if (parentCellId) {
        const parentGraphNode = graph[parentCellId];
        assertIsDefined(parentGraphNode);
        const parentOutputParam = parentGraphNode.outputParams[param];
        assertIsDefined(parentOutputParam);
        parentOutputParam.children.push(cellId);
        // If this is a reference to an existing parameter, instead just use the type of that one
        type = parentOutputParam.type;
      } else if (!GLOBAL_REACTIVE_PARAM_TYPES.includes(type)) {
        const missing = missingDefinitions[param] ?? [];
        missing.push(cellId);
        missingDefinitions[param] = missing;
      }
      graphNode.inputParams[param] = { parentCellId: parentCellId, type };
    }
    // Make sure to handle newParams after we've handled referencedParams, so that if a variable is a redefinition
    // we link it to the previous cell before updating the most recent cell defition
    for (const { param } of newParams) {
      const existingDefinitions = paramDefinitions[param] ?? [];
      const type = automaticParamSet.has(param)
        ? ReactiveParamType.AUTOMATIC_PARAM
        : secretSet.has(param)
          ? ReactiveParamType.SECRET
          : importSet.has(param)
            ? ReactiveParamType.IMPORT
            : functionSet.has(param)
              ? ReactiveParamType.FUNCTION
              : ReactiveParamType.VARIABLE;

      const nodeParamInfo: OutputParamInfo = {
        children: [],
        type,
      };
      const parentCellId = existingDefinitions[0];
      nodeParamInfo.shadowedCellId = parentCellId;
      paramDefinitions[param] = [cellId, ...existingDefinitions];
      graphNode.outputParams[param] = nodeParamInfo;
    }
  }

  // Filter out to only params defined in multiple cells
  const redefinitions = typedObjectFromEntries(
    typedObjectEntries(paramDefinitions).filter(
      ([, cellIds]) => cellIds.length > 1,
    ),
  );
  return {
    missingDefinitions,
    redefinitions,
    graph,
  };
}

export type CellSubGraph = Set<CellId>;

interface ObjectRef<R> {
  value: R;
}

/**
 * Gets all the subgraphs of a full cell graph.
 * A Subgraph contains both a list of CellIds that make up the completely disjoint subgraph, as well as any cycles within the subgraph if they exist.
 *
 * As an example, if the full graph is [] -> A -> [], [B] -> C -> [B], [C] -> B -> [C], [] -> D -> [E], [D] -> E -> []
 * The subgraphs returned would be (nodes, cycles): ([A], []), ([B,C], [[B,C]]), and ([D,E], [])
 */
export const getCellSubgraphs = <C extends BaseGraphCell>({
  graph,
}: {
  graph: CellGraph<C>;
}): CellSubGraph[] => {
  const subGraphMap: Map<CellId, ObjectRef<CellSubGraph>> = new Map();
  for (const cellId of typedObjectKeys(graph)) {
    const subGraphCellIds = new Set<CellId>();
    const subGraphRef: ObjectRef<CellSubGraph> = {
      value: subGraphCellIds,
    };
    updateSubGraphsFromCell({
      cellId,
      graph,
      subGraphMap,
      subGraphRef,
    });
  }
  // Dedupe the subgraphs
  return Array.from(new Set([...subGraphMap.values()].map((ref) => ref.value)));
};

// Helper function to use DFS to generate a list of every subgraph and track cycles along the way
const updateSubGraphsFromCell = ({
  cellId,
  graph,
  subGraphMap,
  subGraphRef,
}: {
  cellId: CellId;
  graph: CellGraph<BaseGraphCell>;
  subGraphMap: Map<CellId, ObjectRef<CellSubGraph>>;
  subGraphRef: ObjectRef<CellSubGraph>;
}): void => {
  const node = graph[cellId];
  assertIsDefined(node);

  const existingSubGraph = subGraphMap.get(cellId)?.value;
  if (existingSubGraph != null) {
    // If we found an existing subgraph thats not our current one, merge the two and update our ref to point to the new merged one
    if (existingSubGraph !== subGraphRef.value) {
      subGraphRef.value.forEach((item) => existingSubGraph.add(item));
      subGraphRef.value = existingSubGraph;
    }
    // If we find an existing subgraph, we've merged with an already partially-computed subgraph, so we're done
    return;
  } else {
    subGraphMap.set(cellId, subGraphRef);
  }

  // Get the references to the subGraph and add the current cellId and cycles to them
  const cellIds = subGraphRef.value;
  cellIds.add(cellId);

  // Recurse for every child in a DFS manner
  const children = Object.values(node.outputParams).flatMap((op) => [
    ...op.children,
  ]);
  for (const childCellId of children) {
    updateSubGraphsFromCell({
      cellId: childCellId,
      graph,
      subGraphMap,
      subGraphRef,
    });
  }
};

/**
 * Given a cell graph, a starting point, and a direction, will use BFS to get an ordered list of all cells
 * in the given direction. Can also optionally include the starting cells in the list returned, or have them be omitted.
 */
export const traverseGraphFromCellsBFS = <C extends BaseGraphCell>({
  cells,
  direction,
  graph,
  includeOriginals = true,
}: {
  graph: CellGraph<C>;
  cells: readonly C[];
  direction: "downstream" | "upstream";
  includeOriginals?: boolean;
}): C[] => {
  const result = [...cells];
  for (let index = 0; result[index] != null; index++) {
    const curr = result[index];
    assertIsDefined(curr);
    const currNode = graph[curr.id];
    if (currNode != null) {
      let linkedCellIds;
      switch (direction) {
        case "downstream":
          linkedCellIds = Object.values(currNode.outputParams).flatMap(
            (outputParam) => [...outputParam.children],
          );
          break;
        case "upstream":
          linkedCellIds = Object.values(currNode.inputParams)
            .map((inputParam) => inputParam.parentCellId)
            .filter(
              (parentCellId): parentCellId is CellId => parentCellId != null,
            );
          break;
        default:
          assertNever(direction, direction);
      }
      const linkedCells = linkedCellIds
        .map((cellId) => graph[cellId]?.cell)
        .filter((c): c is C => c != null);
      for (const linkedCell of linkedCells) {
        if (!result.includes(linkedCell)) {
          result.push(linkedCell);
        }
      }
    }
  }
  if (!includeOriginals) {
    result.splice(0, cells.length);
  }
  // TODO
  return [...result].sort((a, b) =>
    direction === "downstream"
      ? asciiCompare(a.order, b.order)
      : asciiCompare(b.order, a.order),
  );
};

/**
 * Given a graph and a subgraph, will return a topologically sorted list of nodes in the subgraph.
 */
export const topologicalSortSubGraph = <C extends BaseGraphCell>({
  graph,
  subGraph,
}: {
  graph: CellGraph<C>;
  subGraph: CellSubGraph;
}): C[] => {
  const filteredGraph = typedObjectFromEntries(
    typedObjectEntries(graph).filter(([k]) => subGraph.has(k)),
  );
  return topologicalSortFullGraph({ graph: filteredGraph });
};

export const topologicalSortFullGraph = <C extends BaseGraphCell>({
  graph,
}: {
  graph: CellGraph<C>;
}): C[] => {
  // For now since we do everything linearly, this will always just return the graph in order
  return Object.values(graph)
    .sort((a, b) => asciiCompare(a.cell.order, b.cell.order))
    .map((c) => c.cell);
};
