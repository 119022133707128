import { Static, String } from "runtypes";

// to improve this further, we could build a runtype constraint
// that check that this is a string of an expected datetime format
export const DateTimeString = String.withBrand("DateTimeString");
export type DateTimeString = Static<typeof DateTimeString>;

export const getDateTimeString = (date: Date): DateTimeString =>
  date.toISOString() as DateTimeString;

export const TimezoneName = String.withBrand("TimezoneName");
export type TimezoneName = Static<typeof TimezoneName>;
